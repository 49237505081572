<template>
  <div class="login-container">
    <el-form
      ref="loginForm"
      :model="loginForm"
      :rules="loginRules"
      class="login-form"
      autocomplete="on"
      label-position="left"
    >
      <div class="title-container">
        <h3 class="title">互联网医院管理平台</h3>
      </div>
      <span class="nophone" v-if="nophone">请输入手机号</span>
      <el-form-item prop="username">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="username"
          v-model="loginForm.username"
          placeholder="请输入用户名"
          name="username"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
      </el-form-item>
      <el-tooltip
        v-model="capsTooltip"
        content="大写锁定开启"
        placement="right"
        manual
      >
        <el-form-item prop="password">
          <span class="svg-container">
            <svg-icon icon-class="password" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="loginForm.password"
            :type="passwordType"
            placeholder="请输入密码"
            name="password"
            tabindex="2"
            autocomplete="on"
            @keyup.native="checkCapslock"
            @blur="capsTooltip = false"
            @keyup.enter.native="onSubmit"
          />
          <span class="show-pwd" @click="showPwd">
            <svg-icon
              :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'"
            />
          </span>
        </el-form-item>
      </el-tooltip>

      <el-form-item prop="code" class="phone">
        <span class="svg-container">
          <svg-icon icon-class="user" />
        </span>
        <el-input
          ref="code"
          v-model="loginForm.code"
          placeholder="请输入验证码"
          name="code"
          type="text"
          tabindex="1"
          autocomplete="on"
        />
        <span v-if="codeShow" @click="getPhoneCode">获取验证码</span>
        <span v-if="!codeShow">{{ count }}秒后重试</span>
      </el-form-item>
      <el-button
        :loading="loading"
        type="primary"
        class="btn"
        @click.native.prevent="onSubmit"
        >登 录</el-button
      >
    </el-form>

    <div class="slide-container" v-show="showSlide">
      <div class="slide-box animate__animated animate__bounce">
        <slide-verify
          @success="onSuccess"
          @fail="onFail"
          sliderText="向右滑动"
          :w="350"
          :h="175"
          ref="slideVerify"
        ></slide-verify>
        <div class="slide-icon">
          <i class="el-icon-circle-close" @click="showSlide = false"></i
          ><i class="el-icon-refresh" @click="refresh"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlideVerify from "@/components/SlideVerify";
import { loginCode } from "@/api/power/user";
import { validUsername } from "@/utils/validate";
import axios from "axios";

export default {
  name: "Login",
  components: { SlideVerify },
  data() {
    const validateUsername = (rule, value, callback) => {
      console.log(value);
      const reg = /^[1][3,4,5,7,8][0-9]{9}$/;
      if (value == "" || value == undefined || value == null) {
        callback(new Error("请输入用户名"));
      } else {
        if (!reg.test(value) && value != "") {
          callback(new Error("请输入正确的电话号码"));
        } else {
          callback();
        }
      }
    };
    const validatePassword = (rule, value, callback) => {
      if (value.length < 6) {
        callback(new Error("密码不能少于6个字符"));
      } else {
        callback();
      }
    };
    const validateCode = (rule, value, callback) => {
      console.log(value);
      if (value.length < 6) {
        callback(new Error("请输入正确的验证码"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "", // 18888888888
        password: "", // 123456
        code: "",
      },
      loginRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        password: [
          { required: true, trigger: "blur", validator: validatePassword },
        ],
        code: [{ required: true, trigger: "blur", validator: validateCode }],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      redirect: undefined,
      showSlide: false,
      codeShow: true,
      count: "",
      timer: null,
      nophone: false,
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        const query = route.query;
        if (query) {
          this.redirect = query.redirect;
        }
      },
      immediate: true,
    },
    // 刷新验证框
    showSlide(val) {
      if (!val) {
        this.refresh();
      }
    },
  },
  mounted() {
    // if (this.loginForm.username === "") {
    //   this.$refs.username.focus();
    // } else
    if (this.loginForm.password === "") {
      this.$refs.password.focus();
    } else if (this.loginForm.code === "") {
      this.$refs.code.focus();
    }
  },
  methods: {
    checkCapslock(e) {
      const { key } = e;
      this.capsTooltip = key && key.length === 1 && key >= "A" && key <= "Z";
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },
    onSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.showSlide = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    login() {
      this.loading = true;
       
      this.$store
        .dispatch("user/login", this.loginForm)
        .then(() => {
          this.$router.push({ path: this.redirect });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //获取验证码
    getPhoneCode() {
      if (this.loginForm.username) {
        this.nophone = false;
        const TIME_COUNT = 30; //倒计时60秒
        if (!this.timer) {
          this.count = TIME_COUNT;
          this.codeShow = false;
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--;
            } else {
              this.codeShow = true;
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
        let phonenumber = this.loginForm.username;
        loginCode(phonenumber).then(res=>{
          
        })
        // let url = "http://hospital.mingyi.online/api/login/sendsmsCode";
        // axios
        //   .get(url, {
        //     params: { mobile: phonenumber },
        //   })
        //   .then(function (response) {
        //     // console.log(response);
        //   })
        //   .catch(function (error) {
        //     alert(error);
        //   });
      } else {
        this.nophone = true;
      }

      // console.log(t);
    },
    onSuccess() {
      this.showSlide = false;
      this.login();
    },
    onFail() {
      this.$message.error("验证失败");
    },
    refresh() {
      this.$refs.slideVerify.reset();
    },
  },
};
</script>

<style lang="scss">
@supports (-webkit-mask: none) and (not (cater-color: #fff)) {
  .login-container .el-input input {
    color: #fff;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: #fff;
      height: 47px;
      caret-color: #fff;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0.9) inset !important;
        -webkit-text-fill-color: #fff !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
  .phone {
    width: 100%;
    border: none;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .el-input {
      width: 65%;
    }
    span {
      width: 35%;
      color: white;
      text-align: center;
    }
    // align-items: center;
  }
}
</style>

<style lang="scss" scoped>
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background: url(../../assets/login-bg.jpg) no-repeat center center;
  background-size: 100% 100%;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 36px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }
  .nophone {
    color: red;
  }
  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .btn {
    width: 100%;
    height: 50px;
    margin-bottom: 30px;
    font-size: 16px;
  }

  .slide-container {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
  }
  .slide-box {
    position: absolute;
    width: 380px;
    height: 311px;
    background: #e8e8e8;
    border: 1px solid #dcdcdc;
    left: 50%;
    top: 50%;
    margin-left: -188px;
    margin-top: -176px;
    z-index: 99;
    border-radius: 5px;
    .slide-verify {
      margin: 13px auto 0 auto;
      width: 350px !important;
    }
    .slide-verify-slider {
      width: 100% !important;
    }
    .slide-verify-refresh-icon {
      display: none;
    }
  }
  .slide-icon {
    padding: 9px 0 0 19px;
    color: #5f5f5f;
    border-top: 1px solid #d8d8d8;
    margin-top: 17px;
    i {
      font-size: 22px;
      cursor: pointer;
    }
    i:last-child {
      margin-left: 7px;
    }
  }
}
</style>
